<template>
<div>
  <Loader v-if="isLoading" />
  <div class="ai-card-container" v-if="!isLoading">
    <div class="ai-card-title">
      <span>Information that will be known to all agents with whom you will communicate</span>
    </div>
    <v-checkbox
        v-model="AICard.useUserId"
        label="Include User ID"
        @change="() => this.updateInfo()"
    ></v-checkbox>
    <v-checkbox
        v-model="AICard.useCustomerId"
        label="Include Customer ID"
        @change="() => this.updateInfo()"
    ></v-checkbox>
    <div style="margin-top: 10px">
      <v-textarea
          class="TextArea"
          label="Additional Information for AI Model about User"
          outlined
          dense
          hide-details="auto"
          novalidate
          :rows="3"
          v-model="AICard.additionalInfo"
          @change="() => this.updateInfo()"
      ></v-textarea>
    </div>
  </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Loader from "@/components/loader/loader.vue";

const { mapGetters: customerGetters } =
    createNamespacedHelpers('customer');
const { mapGetters: AIChatGetters, mapActions: AIChatActions } =
    createNamespacedHelpers('AIChat');

export default {
  name: 'UserAICardSettings',
  props: {},
  components: {Loader},
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
    }),
    ...AIChatGetters({
      AICard: 'GET_USER_AI_CARD',
      isLoading: 'GET_USER_AI_CARD_LOADING'
    }),
    sh_admin() {
      return this.customer.customer_id === 0;
    },
  },
  data() {
    return {
      useCustomerId: false,
      useUserId: false,
      additionalInfo: '',
    };
  },
  mounted() {
    this.getUserAICard();
  },
  methods: {
    ...AIChatActions(['getUserAICard']),
    ...AIChatActions(['updateUserAICard']),
    updateInfo() {
      this.updateUserAICard({
        useCustomerId: this.AICard.useCustomerId,
        useUserId: this.AICard.useUserId,
        additionalInfo: this.AICard.additionalInfo,
      })
    }
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';
@import 'settings-user-ai-card';
</style>
